export { };

declare global {
  interface Array<T> {
    /**
     * Removes the fist element matching a predicate and returns it.
     * @param predicate A function that accepts up to three arguments.
     * The pop method calls predicate once for each element of the array, in ascending
     * order, until it finds one where predicate returns true. If such an element is found, find
     * immediately returns that element value and removes it from the array. Otherwise, pop returns undefined.
     * @param thisArg If provided, it will be used as the this value for each invocation of
     * predicate. If it is not provided, undefined is used instead.
     */
    popWhere(predicate: (value: T, i?: number, array?: T[]) => boolean, thisArg?: any): T[];
  }
}


if (!Array.prototype.popWhere) {
  Array.prototype.popWhere = function<T>(predicate: (value: T, i?: number, array?: T[]) => boolean, thisArg?: any ): T {
    const index = this.findIndex( (e: T, i: number, a: T[]) => predicate(e, i, a), thisArg );
    if (index >= 0)
      return this.splice(index, 1)[0];
    return undefined;
  };
}
